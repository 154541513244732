/*
==========================
THIS CODE BY RESITDC
ASK ME IF YOU HAVE ANY
QUESTION ABOUT THIS CODE
==========================
*/

import React, { useEffect, useState, useRef } from "react";
import { Layout, Navbar, MenuSidebar } from "components/templates";
import { Body, Flex, FormGroup } from "components/atoms";
import { Profile, Modal } from "components/organisms";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLogin, setToken, setUserData } from "store/actions/userAction.js";
import { isLogin, userData } from "utils/helpers.js";
import { closeSidebarMenu, openSidebarMenu } from "store/actions/templateAction.js";
import { setSubmitAttendance, setDataConference, setAttendance } from "store/actions/attendanceAction.js";
import { conferenceToday, userCheck } from "utils/api";
import AttendanceUnlock from "./AttendanceUnlock";
import AttendanceInput from "./AttendanceInput";
import AttendanceResults from "./AttendanceResults";
import "./index.scss";
import axios from "axios";

const Home = () => {
  // const isLogin = useSelector(state => state.userReducer.isLogin);
  // const userData = useSelector(state => state.userReducer.userData);
  const [showMenu, setShowMenu] = useState(false);
  const [isModalAttendance, setIsModalAttendance] = useState(false);
  const [isGettingConference, setIsGettingConference] = useState(true);
  const [bootcampId, setBootcampId] = useState("1");
  const toggleSidebarMenu = useRef(null);
  const attendanceUnlock = useSelector(state => state.attendanceReducer.unlock);
  const dataConference = useSelector(state => state.attendanceReducer.dataConference);
  const isSubmitAttendance = useSelector(state => state.attendanceReducer.isSubmitAttendance);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    loadConferenceToday();
    dispatch(closeSidebarMenu());
  }, []);

  const loadConferenceToday = () => {
    console.log("userData ID =====>", userData("id"));
    setIsGettingConference(true);
    conferenceToday(3, userData("id"))
      .then((response) => {
        if (response.data.success) {
          const responseConference = response.data.results;
          const totalAttendance = parseInt(responseConference.totalAttendance);
          dispatch(setSubmitAttendance(totalAttendance === 1));
          dispatch(setDataConference(responseConference));
          responseConference.isLocked === false && dispatch(setAttendance(true));
        }
      })
      .catch((error) => {
        console.log("ERROR ===>", error);
      })
      .finally(() => {
        setIsGettingConference(false);
      });
  };

  const openMenu = () => {
    dispatch(openSidebarMenu());
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const openAttendance = () => {
    setIsModalAttendance(true);
  };

  const closeAttendance = () => {
    setIsModalAttendance(false);
    dispatch(setAttendance(false));
  };

  const openTab = (link) => {
    if (link) window.open(link, "_blank");
  };

  const menuAccess = ["god", "superuser", "admin", "content", "reviewer", "moderator"];

  return (
    <Layout>
      {
        menuAccess.includes(userData("role")) &&
        <MenuSidebar toggleSidebarMenu={toggleSidebarMenu} />
      }
      <Navbar>
        <div className="navbar-wrapper">
          <div className="navbar-icon">
            { isLogin() &&
              (
                menuAccess.includes(userData("role")) &&
                  <button type="button" className="btn-show-menu mr-4" onClick={openMenu}>
                    <i className="feather icon-menu"></i>
                  </button>
              )
            }
            <Link to="/teams" className="color-white hidden-anchor fs-md">BACOD TEAMS</Link>
          </div>
          <div className="navbar-program">
            {
              isLogin()
                ? <Profile avatar={userData("avatar")} name={userData("name")} id={userData("id")} />
                : <button type="button" onClick={() => { navigate("/login"); }} className="btn btn-primary btn-rounded bold px-6">LOGIN</button>
            }
          </div>
        </div>
      </Navbar>
      <Body>
        <Modal show={isModalAttendance} size="lg" radius="md" onClose={closeAttendance} className="bg-black-1">
          <div className="px-6 pb-5 pt-5">
            {
              isGettingConference === false &&
              dataConference.isLocked
                ? attendanceUnlock === true
                  ? isSubmitAttendance ? <AttendanceResults onClose={closeAttendance} /> : <AttendanceInput />
                  : <AttendanceUnlock />
                : isSubmitAttendance ? <AttendanceResults onClose={closeAttendance}/> : <AttendanceInput />
            }
          </div>
        </Modal>
        <Flex className="align-x-center align-y-center flex-direction-column height-full-vh">
          <div className="bacod-logo-wrapper">
            <div className="bacod-logo"></div>
          </div>
          {
            isLogin() &&
            <div className="bacod-main-menu mt-10">
              <ul className="primary-menu-list">
                <li className="primary-menu">
                  <div className="primary-menu-button" onClick={() => openTab("https://bacod.id/live-code")}>
                    <div className="primary-menu-circle">
                      <i className="feather icon-monitor"></i>
                    </div>
                    <div className="primary-menu-title">LIVE CODE</div>
                  </div>
                </li>
                <li className="primary-menu">
                  <div className="primary-menu-button" onClick={() => openTab("https://vsc.bacod.id")}>
                    <div className="primary-menu-circle">
                      <i className="feather icon-terminal"></i>
                    </div>
                    <div className="primary-menu-title">VSC</div>
                  </div>
                </li>
                <li className="primary-menu">
                  <div className="primary-menu-button" onClick={() => openTab("https://sketch.bacod.id")}>
                    <div className="primary-menu-circle">
                      <i className="feather icon-tablet"></i>
                    </div>
                    <div className="primary-menu-title">BOARD</div>
                  </div>
                </li>
                <li className="primary-menu">
                  <div className="primary-menu-button" onClick={() => { navigate(`/class-list/${bootcampId}`); }}>
                    <div className="primary-menu-circle">
                      <i className="feather icon-users"></i>
                    </div>
                    <div className="primary-menu-title">CLASS</div>
                  </div>
                </li>
                {
                  isGettingConference === false &&
                  dataConference.isAttendance === true &&
                  !isSubmitAttendance &&
                  <li className="primary-menu">
                    <div className="primary-menu-button" onClick={openAttendance}>
                      <div className="primary-menu-circle">
                        <i className="feather icon-check-square"></i>
                      </div>
                      <div className="primary-menu-title">ATTENDANCE</div>
                    </div>
                  </li>
                }
              </ul>
            </div>
          }
        </Flex>
        {
          isLogin() &&
          isGettingConference === false &&
          dataConference.ongoing === true &&
          <div className="home-information">
            <div className="home-class">
              {
                dataConference.link.map((item, index) => {
                  return (
                    <a
                      href={item.link}
                      target="_blank"
                      type="button"
                      className="btn btn-rounded btn-primary bold px-8 py-2 btn-join-class"
                      title="CLICK HERE FOR JOIN"
                      rel="noreferrer"
                      key={index}
                    >
                      { item.title }
                    </a>
                  );
                })
              }
            </div>
          </div>
        }
      </Body>
    </Layout>
  );
};

export default Home;
